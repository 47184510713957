import "./App.css";
import {useEffect, useState} from "react";
import axios from "axios";
import {Modal} from "antd";
import Screen from "./images/screen.png"
import Oups from "./images/oups.png";

function App() {
    const [loading, setLoading] = useState(true);
    const [country1, setCountry1] = useState("France");
    const [flag1, setFlag1] = useState("")
    const [country2, setCountry2] = useState("Germany");
    const [flag2, setFlag2] = useState("")
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [franceValue, setFranceValue] = useState("");
    const [germanyValue, setGermanyValue] = useState("");
    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [status, setStatus] = useState("");
    const [firstEssai, setFirstEssai] = useState("Benin");
    const [numberOfTransformation, setNumberOfTransaformation] = useState(0);
    const [numberOfPenalities, setNumberOfPenalities] = useState(0);
    const validateForm = () => {
        return name && phone && franceValue && germanyValue;
    };

    const resetFieldsAfterSubmission = () => {
        setFranceValue("");
        setGermanyValue("");
        setPhone("");
        setName("");
        setSuccess(false);
        setFailure(failure);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true)
            axios.post(`${process.env.REACT_APP_API_URL}/pronos`, {
                score_1: franceValue,
                score_2: germanyValue,
                username: name,
                email_or_phone: phone,
                first_try: firstEssai,
                number_of_penalities: numberOfPenalities,
                number_of_transformation: numberOfTransformation
            }).then((resp) => {
                console.log(resp)
                setLoading(false);
                setSuccess(true)
            }).catch((err) => {
                setLoading(false);
                setErrorMessage(err.response.data.message)
                setFailure(true);
            });
        } else {
            alert("Please fill in all required fields.");
        }
    };
    const fetchCurrentMatch = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/matchs/current`).then((resp) => {
            console.log(resp)
            if (resp.data !== null) {
                setCountry1(resp.data.team_1);
                setCountry2(resp.data.team_2);
                setFlag1(resp.data.flag_team_1);
                setFlag2(resp.data.flag_team_2)
            } else alert("Aucun match en cours")

            setLoading(false);
        }).catch((err) => {
            alert(err)
        })
    };

    const fetchSystemStatus = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/settings`).then((resp) => {
            setStatus(resp.data[0].value);
            if (status !== "OFF") {
                fetchCurrentMatch();
            }
        }).catch((err) => {
            alert(err.data.message);
        })
    }
    useEffect(() => {
        fetchSystemStatus();
    }, []);
    return (
        <div className="p-4 flex flex-col md:flex-row">
            <header className="App-header">
                <img src={Screen} alt={"screen"}/>
            </header>
            {
                loading ?
                    <div className={"flex flex-col items-center my-10"}>
                        <div
                            className="relative items-center block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md ">
                            <h5 className="mb-2 text-2xl font-bold tracking-tight text-black opacity-50">Pronostic
                                en cours de création</h5>
                            <div role="status" className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
                                <svg aria-hidden="true"
                                     className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-pink-600"
                                     viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"/>
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"/>
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div> :
                    <div
                        className={"px-2 md:flex-col md:items-center md:pt-20  md:w-1/2 "}>
                        <Modal
                            open={success}
                            onOk={() => setSuccess(false)}
                            footer={null}
                            closable={false}
                        >
                            <div className={"flex flex-col items-center"}>
                                <div className={"p-8 bg-gray-100 rounded-full"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="127" height="127"
                                         viewBox="0 0 127 127"
                                         fill="none">
                                        <path
                                            d="M127 63.502C127 76.0612 123.275 88.3381 116.297 98.7804C109.319 109.223 99.4018 117.361 87.7985 122.167C76.1952 126.973 63.4275 128.23 51.1098 125.779C38.7921 123.329 27.4776 117.281 18.5973 108.4C9.71692 99.5189 3.66953 88.2041 1.21977 75.8862C-1.23 63.5683 0.0279155 50.8007 4.83445 39.1977C9.64099 27.5948 17.7803 17.6777 28.223 10.7006C38.6658 3.72342 50.9429 -0.00040515 63.5021 3.30618e-08C71.841 0.000269064 80.0982 1.64302 87.8023 4.83444C95.5064 8.02586 102.506 12.7035 108.403 18.6002C114.299 24.4969 118.976 31.4972 122.167 39.2015C125.358 46.9058 127 55.1631 127 63.502ZM56.1616 97.1139L103.265 50.0102C103.647 49.63 103.949 49.1783 104.156 48.6811C104.362 48.1838 104.468 47.6507 104.468 47.1123C104.468 46.5739 104.362 46.0408 104.156 45.5436C103.949 45.0463 103.647 44.5946 103.265 44.2145L97.4697 38.4611C97.0902 38.0801 96.6393 37.7779 96.1427 37.5716C95.6461 37.3653 95.1137 37.2592 94.576 37.2592C94.0383 37.2592 93.5058 37.3653 93.0093 37.5716C92.5127 37.7779 92.0617 38.0801 91.6822 38.4611L53.2642 76.8799L35.326 58.8993C34.5559 58.1336 33.5141 57.7037 32.4281 57.7037C31.3421 57.7037 30.3003 58.1336 29.5303 58.8993L23.7346 64.7292C22.9688 65.4992 22.539 66.5411 22.539 67.627C22.539 68.713 22.9688 69.7548 23.7346 70.5249L50.3673 97.1481C50.7475 97.5293 51.1992 97.8317 51.6964 98.0381C52.1937 98.2445 52.7268 98.3507 53.2652 98.3507C53.8035 98.3507 54.3367 98.2445 54.8339 98.0381C55.3312 97.8317 55.7828 97.5293 56.163 97.1481L56.1616 97.1139Z"
                                            fill="#DD1AB2"/>
                                    </svg>
                                </div>
                                <div className={"my-8 text-xl font-bold"}>
                                    <span>
                                        Pronostic soumis avec succès
                                    </span>
                                </div>
                                <div className={"w-full mt-20"}>
                                    <button
                                        onClick={resetFieldsAfterSubmission}
                                        className="flex w-full py-2.5 bg-pink-600 justify-center rounded-md px-3 text-xl font-semibold leading-6 text-white shadow-sm hover:bg-pink-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Retour
                                    </button>
                                </div>
                            </div>
                        </Modal>
                        <Modal
                            open={failure}
                            onOk={() => setFailure(false)}
                            footer={null}
                            closable={false}
                        >
                            <div className={"flex flex-col items-center"}>
                                <div className={"p-8 bg-gray-100 rounded-full"}>
                                    <img src={Oups} alt={"error"}/>
                                </div>
                                <div className={"my-8 text-xl font-bold text-center"}>
                                    <span>
                                        {errorMessage}
                                    </span>
                                </div>
                                <div className={"w-full mt-20"}>
                                    <button
                                        onClick={() => setFailure(false)}
                                        className="flex w-full py-2.5 bg-pink-600 justify-center rounded-md px-3 text-xl font-semibold leading-6 text-white shadow-sm hover:bg-pink-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Retour
                                    </button>
                                </div>
                            </div>
                        </Modal>
                        <div className="flex md:flex-row min-h-full flex-1 flex-col justify-center px-6 py-2 lg:px-8">
                            {
                                status === "OFF" ? <div className={"flex flex-col items-center my-3 text-center"}>
                                        <span>Le match a commencé, aucun paris possible actuellement</span>
                                        <img src={Oups} alt={"error"}/>
                                    </div> :
                                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                                        <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit}>
                                            <div>
                                                <label
                                                    htmlFor="email"
                                                    className="block text-sm font-medium leading-6 text-gray-900"
                                                >
                                                    Nom et Prenoms
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="name"
                                                        name="name"
                                                        type="text"
                                                        required
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        className="block w-full rounded-lg border-0 py-2.5 px-2 bg-gray-50 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <div className="flex items-center justify-between">
                                                    <label
                                                        htmlFor="password"
                                                        className="block text-sm font-medium leading-6 text-gray-900"
                                                    >
                                                        Telephone
                                                    </label>
                                                </div>
                                                <div className="mt-2">
                                                    <input
                                                        id="phone_number"
                                                        name="phone_number"
                                                        type="phone"
                                                        required
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-2.5 px-2 bg-gray-50 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className={"py-2.5"}>
                                                <div
                                                    className="flex w-full my-4"
                                                    style={{
                                                        boxShadow: "0px 4px 4px 0px rgba(221, 26, 178, 0.12)",
                                                    }}
                                                >
                                                    <div
                                                        className="w-1/6 inline-flex items-center px-0 text-sm text-gray-900 border border-r-0 border-gray-300 rounded-l-md">
                                                        <img
                                                            src={flag1}
                                                            alt={"kn"}
                                                            className={"w-16 h-full"}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            "flex flex-row items-center justify-center  w-2/3 text-2xl font-bold"
                                                        }
                                                        style={{color: "#2A3256"}}
                                                    >
                                                        {country1}
                                                    </div>
                                                    <input
                                                        type="text"
                                                        id="website-admin"
                                                        className="text-center  rounded-lg border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block font-bold text-xl flex-1 min-w-0 w-full p-2.5"
                                                        placeholder="74"
                                                        required
                                                        value={franceValue}
                                                        onChange={(e) => setFranceValue(e.target.value)}
                                                        style={{color: "#DD1AB2"}}
                                                    />
                                                </div>
                                                <div className={"flex flex-row items-center justify-center "}>
                <span className={"font-bold text-2xl"} style={{color: "#2A3256"}}>
                  VS
                </span>
                                                </div>
                                                <div
                                                    className="flex w-full"
                                                    style={{
                                                        boxShadow: "0px 4px 4px 0px rgba(221, 26, 178, 0.12)",
                                                    }}
                                                >
                                                    <div
                                                        className="w-1/6 inline-flex items-center px-0 text-sm text-gray-900 border border-r-0 border-gray-300 rounded-l-md">
                                                        <img
                                                            src={flag2}
                                                            alt={"kn"}
                                                            className={"w-16 h-full"}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            "flex flex-row items-center justify-center  w-2/3 text-2xl font-bold"
                                                        }
                                                        style={{color: "#2A3256"}}
                                                    >
                                                        {country2}
                                                    </div>
                                                    <input
                                                        required
                                                        value={germanyValue}
                                                        onChange={(e) => setGermanyValue(e.target.value)}
                                                        type="text"
                                                        id="website-admin"
                                                        className="text-center  rounded-lg border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block font-bold text-xl flex-1 min-w-0 w-full p-2.5"
                                                        placeholder="74"
                                                        style={{color: "#DD1AB2"}}
                                                    />
                                                </div>
                                            </div>
{/*                                            <div>
                                                <label htmlFor="essai"
                                                       className="block text-sm font-medium leading-6 text-gray-900">Qui
                                                    marque le 1er essai</label>
                                                <select id="first_try" name="first_try"
                                                        onChange={(e) => setFirstEssai(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-2.5 px-2 bg-gray-50 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                    <option value={country1}>{country1}</option>
                                                    <option value={country2}>{country2}</option>
                                                </select>
                                            </div>

                                            <div>
                                                <div className="flex items-center justify-between">
                                                    <label
                                                        htmlFor="penalities"
                                                        className="block text-sm font-medium leading-6 text-gray-900"
                                                    >
                                                        Nombre de Pénalités
                                                    </label>
                                                </div>
                                                <div className="mt-2">
                                                    <input
                                                        id="penalities_number"
                                                        name="penalities_number"
                                                        type="number"
                                                        required
                                                        value={numberOfPenalities}
                                                        onChange={(e) => setNumberOfPenalities(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-2.5 px-2 bg-gray-50 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <div className="flex items-center justify-between">
                                                    <label
                                                        htmlFor="transformation"
                                                        className="block text-sm font-medium leading-6 text-gray-900"
                                                    >
                                                        Nombre de Transformations
                                                    </label>
                                                </div>
                                                <div className="mt-2">
                                                    <input
                                                        id="transformation_number"
                                                        name="transformation_number"
                                                        type="number"
                                                        required
                                                        value={numberOfTransformation}
                                                        onChange={(e) => setNumberOfTransaformation(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-2.5 px-2 bg-gray-50 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>*/}

                                            <div>
                                                <button
                                                    type="submit"
                                                    className="flex w-full py-2.5 bg-pink-600 justify-center rounded-md px-3 text-xl font-semibold leading-6 text-white shadow-sm hover:bg-pink-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    Soumettre
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                            }
                        </div>
                    </div>
            }
        </div>
    );
}

export default App;
